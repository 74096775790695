import DataTable from 'datatables.net-bs4'

//Global setting and initializer

$.extend( $.fn.dataTable.defaults, {
  responsive: true,
  pagingType: 'full',
});


import 'lib/colreorder'
import 'datatables.net-select'
import 'datatables.net-select-bs4'

// import 'yadcf'
import 'lib/jquery.dataTables.yadcf'


$(document).on('preInit.dt', function(e, settings) {
  var api, table_id, url;
  api = new $.fn.dataTable.Api(settings);
  table_id = "#" + api.table().node().id;
  url = $(table_id).data('source');
  if (url) {
    return api.ajax.url(url);
  }
});

$(window).resize(function() {
  var dataTable = $("table[id^=dttb-]");
  if(0 !== dataTable.length) {
    resizeTable();
  }
});

function resizeTable() {
  var a = $('body').height(), b =$('.container-fluid').height(), c =$('.main-pane').height(), d = $('.dataTables_scrollBody').height() || 0;
  // var a = Math.max($('body').height(), Math.min($('.container-fluid').height(), $('.main-pane').height())), d = $('.dataTables_scrollBody').height() || 0;
  $('.dataTables_scrollBody').css('max-height', 'calc(100vh - '+(b - d)+'px)');
}

window.resizeTable = resizeTable;

// init on turbolinks load
$(document).on('turbolinks:load', () => {
  "use strict";

  // init dataTable with ajax
  var dataTable = $("table[id^=dttb-]");
  if(0 === dataTable.length) {
    return;
  }

  dataTable.on('xhr.dt', function ( e, settings, json, xhr ) {
    if (json != null && json.sumTotalOrderValueNet) {
      if ($('.dataTables_length label.sum').length == 0) {
        $('.dataTables_length').append("<label class='sum'>");
      }
      var sum = Number.parseFloat(json.sumTotalOrderValueNet).toLocaleString('de', {'style': 'currency', 'currency': 'EUR'})
      $('.dataTables_length label.sum').html(
        "&nbsp;| Summe: " + sum
      );
    } else {
      $('.dataTables_length label.sum').html('');
    }
  })

  var oTable;

  oTable = dataTable.DataTable({
    pageLength: 50,
    lengthMenu: [50, 100, 200],
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    scrollX: true,
    // 'scrollY':'70vh',
    scrollCollapse: true,
    bAutoWidth: false,
    ajax: {
      url: dataTable.data('source'),
      data: { authenticity_token: $('[name="csrf-token"]')[0].content },
      type: 'PUT'
    },
    fixedColumns: { leftColumns: 1 },
    // "pagingType": "full_numbers",
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.

    // "columns": [
    //   // {"data": "id"},
    //   // {"data": "title"},
    // ]
    select: {
            style:    'os multi',
            selector: 'td:first-child'
        },
    stateSaveParams: function(settings, data) {
      var cWidths = [];
      for(var i = 0; i < settings.aoColumns.length; i++) {
        var idx = settings.aoColumns[i].hasOwnProperty('_ColReorder_iOrigCol') ?settings.aoColumns[i]._ColReorder_iOrigCol : i;
        if(settings.aoColumns[i].hasOwnProperty('sWidth')) {
          cWidths[idx] = settings.aoColumns[i].sWidth;
        }
      }
      if(0 < cWidths.length) {
        data['ColumnWidths'] = cWidths;
      }
    },
    stateLoadParams: function(settings, data) {
      var cWidths = data.hasOwnProperty('ColumnWidths') ? data.ColumnWidths : [];
      for(var i = 0; i < settings.aoColumns.length; i++) {
        var idx = settings.aoColumns[i].hasOwnProperty('_ColReorder_iOrigCol') ? settings.aoColumns[i]._ColReorder_iOrigCol : i;
        settings.aoColumns[i].sWidth = (cWidths.length > idx && null != cWidths[idx]) ? cWidths[idx] : '120px';
        // if(settings.aoColumns[i].hasOwnProperty('sWidth')) {
        //   cWidths[idx] = settings.aoColumns[i].sWidth;
        // }
      }
      // if(0 < cWidths.length) {
      //   data['ColumnWidths'] = cWidths;
      // }
    },
    columnDefs: [{
            orderable: false,
            className: 'select-checkbox',
            targets:   0
        }, {targets: '_all', render: function ( data, type, row, meta ) {
      if('display' === type) {
        var col = meta.settings.aoColumns[meta.col],
          copy = ['title', 'file_path', 'email'],
          shorten = ['general_infos', 'target_languages'],
          tf = ['pm', 'trados', 'memoq', 'budget_responsibility', 'purchase_limit', 'offer_limit', 'power_of_procuration', 'has_gh', 'has_gh_fe', 'has_gh_basf', 'has_gh_hyundai', 'as_email', 'black_listed'];
        if(col.sName.match(/(_at)$/)) {
          var d = data.match(/\d{2}\.\d{2}\.\d{4}/);
          return (null !== d) ? d[0] : data;
        } else if(copy.includes(col.sName)) {
          return '<div><div>'+data + '</div><a class="js-tooltip js-copy" title="copy to clipboard" data-toggle="tooltip" data-placement="bottom"><span class="fa fa-clipboard"></span</a></div>';
        } else if(shorten.includes(col.sName)) {
          var max_length = 65;
          return data.length > max_length ? '<div><div><span title="'+data+'" class="has_tt">'+data.substr( 0, max_length )+'...</span></div></div>' : '<div><div>'+data+'</div></div>';

        } else if('id' === col.sName && meta.settings.oInit.hasOwnProperty('controllerpath')) {
          // var p = meta.settings.ajax.url.substring(1, meta.settings.ajax.url.length-5);
          return '<a target="'+meta.settings.oInit.controllerpath+'_'+data+'" href="/'+meta.settings.oInit.controllerpath+'/'+data+'">#'+data+'</a>';
        } else if(tf.includes(col.sName)) {
          if('true' == data) {
            return '<i class="far fa-check-square text-success"></i>';
          } else if('false' == data) {
            return '<i class="far fa-square text-black-50"></i>';
          }
        } else if('sex' == col.sName) {
          switch (data.toLowerCase()) {
            case 'male':
              return '<i class="fas fa-mars"></i>'
            case 'female':
              return '<i class="fas fa-venus"></i>';
            case 'd':
              return '<i class="fas fa-transgender-alt"></i>';
          }
        }
      }
      return '<div><div>'+data+'</div></div>';
    }}],
    // rowCallback: function ( row, data, disyNum, disIdx, dataIdx ) {
    //   $(row).prepend('<td></td>');
    //   // console.log(data, row);
    //   // return type === 'display' && data.length > 40 ?
    //   //   '<span title="'+data+'">'+data.substr( 0, 38 )+'...</span>' :
    //   //   data;
    // },
    drawCallback: function() {
      $('td span.has_tt, .js-tooltip').tooltip();

      // console.log(table.state());
    },
    initComplete: function() {
      $('td span.has_tt, .js-tooltip').tooltip();
      $('.dataTables_scrollBody').on('scroll', function () {
        var drop = $('.dropup.show');
        if(0 < drop.length) {
          $('th#'+drop.prop('id').substr(17)).find('[data-toggle=dropdown]').dropdown('update')
        }
      });
    },
    columns: dataTable.data('columns'),
    stateSave: true,
    stateDuration: 0,
    stateLoadCallback: function(settings) {
      // console.log('stateLoadCallback');
      // console.log(settings);
      var dttbSettings;
      if (dttbSettings = dataTable.data('settings')) {
        var columns_length = dataTable.find('thead th').length;
        // var columns_length = settings.aoColumns.length;
        // console.log(dttbSettings.ColReorder);
        // console.log(dttbSettings);
        if (dttbSettings.hasOwnProperty('ColReorder') && columns_length != dttbSettings.ColReorder.length) {
          for (var i = dttbSettings.ColReorder.length-1; i < columns_length; i++) {
            dttbSettings.ColReorder[i] = i;
            dttbSettings.columns[i] = dttbSettings.columns[i-1];
          }
        }
        // console.log(dttbSettings.ColReorder);
        // console.log(dttbSettings);
        localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(dttbSettings) );
        return dttbSettings ;
      } else {
        return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) );
      }
    },
    stateSaveCallback: function(settings, data) {
      // console.log('stateSaveCallback');
      // console.log(settings);
      // console.log(data);
      $.post("/profile", { settings: JSON.stringify(data), instance: settings.sInstance } );
      localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
    }
  });

  document.oTable = oTable;

  setTimeout(function (oTable) {
    yadcf.init(
      oTable,
      dataTable.data('yadcf'),
      {
        filter_reset_button_text: '<span class="fa fa-times"></span>',
        reset_button_style_class: 'btn btn-primary btn-sm ml-1',
        style_class: 'form-control',
        datepicker_type:'bootstrap-datetimepicker',
        moment_date_format: 'YYYY-MM-DDTHH:mm',
        filter_plugin_options: {
          format: 'YYYY-MM-DD',
          showClear: true,
          locale: moment.locale('de'),
          allowInputToggle: true,
          buttons: {showToday:true, showClear: true, showClose: true},
          icons: {date: 'far fa-calendar-alt', clear: 'far fa-calendar-times', today: 'fas fa-calendar-day', close: 'fa fa-times'},
          keepInvalid: true,
        }
      }
      // {
      //   onInitComplete: function() {
      //     console.log('onInitComplete');
      //     $('#dttb-wrapper').removeClass('invisible');
      //   }
      // }
    );

    setTimeout(function(oTable) {build_column_vis(true, oTable);}, 50, oTable);
    
  }, 150, oTable);


  if ($.fn.DataTable.isDataTable(oTable)) {
    new $.fn.dataTable.ColReorder(oTable);
    oTable.on( 'select deselect', function ( e, dt, type, indexes ) {
      var elems = oTable.rows({selected:true}).data().toArray();
      if(0 < elems.length) {
        $('#project_ids').val($.map(elems, function(elem) {return elem.id;}).join(' '));
        $('#bulkEditButton').attr('disabled', false);
      } else {
        $('#project_ids').val('');
        $('#bulkEditButton').attr('disabled', true);
      }
    });
  }

  $('input.yadcf-filter-range-date').each(function(){$(this).prop('autocomplete', 'off')});

  // var table = $("table[id^=dttb-]").DataTable();
  var table = oTable;

  function build_column_vis(change_header, table) {
    var len = table.columns()[0].length, tSet = table.settings()[0].aoColumns;
    for(var i = 0; i < len; i++) {
      var h = table.column(i).header();
      if('terminator_dummy' === h.id) {
        continue;
      }
      if(true === change_header && 0 === $('.filter-header', h).length){
        var t = $(h).html();
        var f = '<div class="filter-header dropup"><div class="filter'+($('#filter-wrapper-'+tSet[i].sName).children().length > 0 ? ' dropdown-toggle" data-toggle="dropdown" data-target="#filter-container-'+tSet[i].sName+'"><label>Filter</label>' : '">')+'F</div></div>';
        h = $(h).html(f+'<div class="col-title"><label>'+t+'</label>'+t.substr(0,1)+'</div>');
        // $('.filter-header', table.columns().column(i).header()).append($('#filter-container-'+i));
      }
      $('#'+table.context[0].nTable.id+'-columns ul').append(
        '<li><a href="#" class="btn btn-block btn-sm btn-light'+(table.column(i).visible() ? ' dttb-col-vis' : '')+'" data-value="'+i+'" tabIndex="-1">'
        // +'<input type="checkbox" '+(table.column(i).visible() ? 'checked' : '')+'/>'
        +'<span class="fa fa-check"></span>&nbsp;'+$('.col-title label', h).first().text()+'</a></li>')
    }
  }

  $('#'+table.context[0].nTable.id+'-columns ul').on('click', 'a', function(evnt) {
    var $target = $( evnt.currentTarget ),
       val = $target.attr( 'data-value' ),
       // $inp = $target.find( 'input' ),
       vis = !table.column(val).visible();
    table.column(val).visible(vis);
    vis ? $target.addClass('dttb-col-vis') : $target.removeClass('dttb-col-vis');
    $( evnt.target ).blur();
    evnt.preventDefault();
    // setTimeout( function() { $inp.prop( 'checked', vis);});
    return false;
  });

  dataTable.on('column-reorder.dt.mouseup', function(ev){
    $('#'+table.context[0].nTable.id+'-columns ul').empty();
    build_column_vis(false, table);
  }).on('draw.dt', function(ev){
    setTimeout(function(){
      var len = table.columns()[0].length, pos = table.colReorder.order(), tSet = table.settings()[0].aoColumns, actives = false;
      for(var i = 0; i < len; i++) {
        var isAct = 0 < $('#filter-wrapper-'+tSet[i].sName+' .inuse, #filter-wrapper-'+tSet[i].sName+' .chosen-choices .search-choice').length;
        actives = (isAct ? true : actives);
        $('.filter-header', table.columns().column(i).header()).toggleClass('active', isAct);
      }
      var wr = $(ev.target).closest('.dataTables_wrapper');
      $('.clear-filters', wr).prop('disabled', !actives);
      $('.dataTables_scrollHead table', wr).toggleClass('filter-active', actives);
    }, 100);

    setTimeout(resizeTable(), 200);
    // console.log(ev.type);
  });

    $('.dataTables_filter')
    .append($('#'+table.context[0].nTable.id+'-columns'))
    .prepend('<button class="btn btn-primary btn-sm clear-filters"><span class="fa fa-times"></span> clear all Filters</button> ')
    .on('click', '.clear-filters', function() {
      yadcf.exResetAllFilters(oTable);
      oTable.search("").columns().search("").draw();
    }).on('change', 'input[type="search"]', function(e) {
      $(e.target).toggleClass('filled', '' != e.target.value)
    });
  setTimeout(function () {$('input[type="search"]').toggleClass('filled', '' != $('input[type="search"]').val())}, 50);
  $('th').on('click', '.filter-header, .dropdown-toggle, .filter, .filter label', function(ev) {
    var elem = $(this).closest('th').find('[data-toggle=dropdown]');
    if(elem.length == 0) {
      return true;
    }
    ev.preventDefault();
    ev.stopPropagation();
    elem.dropdown('toggle').dropdown('update');
    setTimeout(function () {
      $(elem.data('target')).find('input').first().focus();
    }, 50);
    return false;
  });
  var a = 0;
  setTimeout(resizeTable(), 200);
});

// turbolinks cache fix
$(document).on('turbolinks:before-cache', function() {
  var dataTable = $($.fn.dataTable.tables(true)).DataTable();
  if (dataTable !== null) {
    if(dataTable.context.length > 0 && 'dttb-' == dataTable.context[0].sTableId.substr(5))
      dataTable.clear();
    dataTable.destroy();
    return dataTable = null;
  }
}).on('mousedown click', '.filterWrapper .dropdown-menu', function (event) {
  yadcf.stopPropagation(event);
}).on('keydown', '.filterWrapper .dropdown-menu', function (event) {
  yadcf.preventDefaultForEnter(event);
});

$('.dataTables_scrollBody').on('scroll', function () {
  var drop = $('.dropup.show');
  if(0 < drop.length) {
    $('th#'+drop.prop('id').substr(17)).find('[data-toggle=dropdown]').dropdown('update')
  }
});
