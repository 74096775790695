window.init_date_pick = ->
  $.each $('.da-pick'), ->
    $(this).prop('data-toggle', 'datetimepicker').prop('data-target', '#' + this.id).prop('spellcheck', false)
    $(this).datetimepicker {
      debug: false
      locale: moment.locale('de')
      allowInputToggle: true
      format: 'YYYY-MM-DD'
      readonly: true
      ignoreReadonly: true
      buttons: { showToday: true, showClear: true, showClose: true }
      icons: { time: 'far fa-clock', date: 'far fa-calendar-alt', clear: 'far fa-calendar-times', today: 'fas fa-calendar-day', close: 'fa fa-times' }
      keepInvalid: true
      useCurrent: false
      minDate: $(this).data('mindate') if $(this).data('mindate')
    }
    return

get_range_selection = (elem) ->
  if pres_admin == 'true'
    user = elem.parentElement.className.match(/user-(\d+)/)[1]
  etype = elem.firstElementChild.className
  firstdate = lastdate = elem
  weekend = false
  weekends = 0
  while firstdate.previousElementSibling && (etype == firstdate.previousElementSibling.firstElementChild.className || ((firstdate.previousElementSibling.classList.contains('freeday') || firstdate.previousElementSibling.classList.contains('personal_freeday')) && '' == firstdate.previousElementSibling.firstElementChild.className))
    if (firstdate.previousElementSibling.classList.contains('freeday') || firstdate.previousElementSibling.classList.contains('personal_freeday'))
      weekends++
      if etype == firstdate.previousElementSibling.firstElementChild.className
        weekend = true
    firstdate = firstdate.previousElementSibling
  while firstdate && firstdate != this && (firstdate.classList.contains('freeday') || firstdate.classList.contains('personal_freeday')) && etype != firstdate.firstElementChild.className
    weekends--
    firstdate = firstdate.nextElementSibling
  while lastdate.nextElementSibling && (etype == lastdate.nextElementSibling.firstElementChild.className || ((lastdate.nextElementSibling.classList.contains('freeday') || lastdate.nextElementSibling.classList.contains('personal_freeday')) && '' == lastdate.nextElementSibling.firstElementChild.className))
    if (lastdate.nextElementSibling.classList.contains('freeday') || lastdate.nextElementSibling.classList.contains('personal_freeday'))
      weekends++
      if etype == lastdate.nextElementSibling.firstElementChild.className
        weekend = true
    lastdate = lastdate.nextElementSibling
  while lastdate && lastdate != this && (lastdate.classList.contains('freeday') || lastdate.classList.contains('personal_freeday')) && etype != lastdate.firstElementChild.className
    weekends--
    lastdate = lastdate.previousElementSibling
  if 0 == weekends
    weekend = false
  dfdate = $('table#presences th:not(.month)').eq($(firstdate).index()).data('date')
  if 'true' != pres_admin && dfdate < pres_today
    if lastdate != firstdate
      dldate = $('table#presences th:not(.month)').eq($(lastdate).index()).data('date')
      if(dldate < pres_today)
        $('input#presence_from').datetimepicker('date', null)
        $('input#presence_to').datetimepicker('date', null)
      else if pres_today < dldate
        $('input#presence_from').datetimepicker('date', pres_today)
        $('input#presence_to').datetimepicker('date', dldate)
        $('input#presence_weekend').prop('checked', weekend)
        $('#presence_status').val(etype).change()
      else
        $('input#presence_from').datetimepicker('date', pres_today)
        $('input#presence_to').datetimepicker('date', null)
        $('input#presence_weekend').prop('checked', weekend)
        $('#presence_status').val(etype).change()
    else
      $('input#presence_from').datetimepicker('date', null)
      $('input#presence_to').datetimepicker('date', null)
      if(0 < etype.length)
        $('#presence_status').val(etype).change()
  else
    $('input#presence_from').datetimepicker('date', $('table#presences th:not(.month)').eq($(firstdate).index()).data('date'))
    if lastdate != firstdate
      $('input#presence_to').datetimepicker('date', $('table#presences th:not(.month)').eq($(lastdate).index()).data('date'))
    else
      $('input#presence_to').datetimepicker('date', null)
    $('input#presence_weekend').prop('checked', weekend)
    $('#presence_status').val(etype).change()
  if pres_admin == "true"
    $('#presence_user_id').val(user).change().trigger('chosen:updated')
    $('#ueberstunden_antrag_option').remove()
    if etype == 'beantragter_urlaub'
      $('.btn-vac').removeClass('d-none')
    else if etype == 'ueberstunden_antrag'
      $('.btn-ot').removeClass('d-none')
      opt = new Option("Überstunden Abbau - Antrag", etype, false. true)
      opt.id = etype + '_option'
      document.getElementById('presence_status').appendChild(opt)
    else
      $('.btn-vac, .btn-ot').addClass('d-none')
  else
    $('.btn-vac, .btn-ot').addClass('d-none')

ccResizeTable = ->
  t = $('.ccTable')
  # TODO: figure out, whats the problem
  # if(0 == t.length)
  #   return
  # b = $('.container-fluid').height()
  # d = t.height() || 0;
  # t.css('max-height', 'calc(100vh - '+(b - d)+'px)')
  # return

copyToClipboard = (text, el) ->
  copyTest = document.queryCommandSupported('copy')
  elOriginalText = el.attr('data-original-title')

  if (copyTest == true)
    copyTextArea = document.createElement("textarea")
    copyTextArea.value = text
    document.body.appendChild(copyTextArea)
    copyTextArea.select()
    try
      successful = document.execCommand('copy')
      if successful
        msg = 'Copied!'
      else
        msg = 'Whoops, not copied!'
      el.attr('data-original-title', msg).tooltip('show')
    catch err
      console.log('Oops, unable to copy')

    document.body.removeChild(copyTextArea)
    setTimeout(->
      el.attr('data-original-title', elOriginalText)
    , 1000)
  else
    # Fallback if browser doesn't support .execCommand('copy')
    window.prompt("Copy to clipboard: Ctrl+C or Command+C, Enter", text)

update_selection = (pres) ->
  setTimeout ->
    $('table#presences tr td.selected').removeClass('selected')
    last = $('input#presence_to').datetimepicker('date')
    first = $('input#presence_from').datetimepicker('date')
    is_vac = is_ot = pres_admin == "true"
    if !first
      if last
        $('input#presence_to').datetimepicker('date', null)
      return
    idx = endidx = $('table#presences th:not(.month)[data-date=' + first.format("YYYY-MM-DD") + ']').index('table#presences th:not(.month)')
    if last
      endidx = $('table#presences th:not(.month)[data-date=' + last.format("YYYY-MM-DD") + ']').index('table#presences th:not(.month)')
    while idx <= endidx
      elem = $('table#presences tr.user-' + $('#presence_user_id').val() + ' td').eq(idx)
      elem.addClass('selected')
      is_vac = is_vac && (0 < $('.beantragter_urlaub', elem).length || ((elem.hasClass('freeday') || elem.hasClass('personal_freeday')) && $('span', elem)[0].className == '' && last))
      is_ot = is_ot && (0 < $('.ueberstunden_antrag', elem).length || ((elem.hasClass('freeday') || elem.hasClass('personal_freeday')) && $('span', elem)[0].className == '' && last))
      idx++
    if !is_vac
      $('.btn-vac').addClass('d-none')
    else
      $('.btn-vac').removeClass('d-none')
    if !is_ot
      $('.btn-ot').addClass('d-none')
    else
      $('.btn-ot').removeClass('d-none')
  , 50

calc_35 = (time) ->
  "#{(time * 35).toFixed 2} €"

calc_times = ->
  ns_count = $('#translator_order_new_norm_pages').val()
  $('#count-ns').text ns_count
  time_translation = ns_count / 2
  $('#time-translation').text time_translation.toFixed(1)
  time_review = ns_count / 4
  $('#time-review').text time_review.toFixed(1)
  time_qs = ns_count / 5
  $('#time-qs').text time_qs.toFixed(1)
  $('#cost-35-translation').text calc_35 time_translation
  $('#cost-35-review').text calc_35 time_review
  $('#cost-35-qs').text calc_35 time_qs

set_for = (rec = true) ->
  int = $('.translator_order_for input:checked').val()
  if int == 'intern'
    $('input, textfield, select', $('.ext').addClass('hide')).attr 'disabled', true
    $('input, textfield, select', $('.int').removeClass('hide')).attr 'disabled', false
    $('.translator_order_translator_order_items_amount input').attr 'disabled', true
  else
    $('input, textfield, select', $('.int').addClass('hide')).attr 'disabled', true
    $('input, textfield, select', $('.ext').removeClass('hide')).attr 'disabled', false
    $('.translator_order_translator_order_items_amount input').attr 'disabled', false
  if int == 'extern_en'
    $('.lang-toggable .de').addClass 'hide'
    $('.lang-toggable .en').removeClass 'hide'
  else
    $('.lang-toggable .en').addClass 'hide'
    $('.lang-toggable .de').removeClass 'hide'

set_on_total_order_value_net = ->
  if $('.project_reason_on_total_order_value_net :selected').text().substr(-1) == '%'
    $('.project_percentage_on_total_order_value_net').removeClass('invisible')
  else
    $('.project_percentage_on_total_order_value_net').addClass('invisible')

set_mailing_address_type = ->
  $('#mailing_addresses .user_mailing_addresses_as_email input[type=checkbox]').each ->
    if @.checked
      $(@).closest('.nested-fields').addClass('invisible_address')
    else
      $(@).closest('.nested-fields').removeClass('invisible_address')

set_trackable = ->
  $('.trackable_id select').attr 'disabled', true
  $('.trackable_id').addClass 'hide'
  type = $('#activity_trackable_type').val()
  if '' != type
    $(".trackable_id##{type} select").attr('disabled', false).trigger("chosen:updated")
    $(".trackable_id##{type}").removeClass 'hide'

get_kind_type = ->
  $("#additional_costs .project_additional_costs_kind select").each ->
    if $(@).find(':selected').text().substr(-1) == '%'
      $(@).closest('.nested-fields').find('input[name*="[hours]"], input[name*="[amount]"]').addClass('invisible')
      $(@).closest('.nested-fields').find('input[name*="[price_per_hour]"]').addClass('invisible')
      $(@).closest('.nested-fields').find('input[name*="[percentage]"]').removeClass('invisible')
    else
      $(@).closest('.nested-fields').find('input[name*="[percentage]"]').addClass('invisible')
      $(@).closest('.nested-fields').find('input[name*="[price_per_hour]"]').removeClass('invisible')
      $(@).closest('.nested-fields').find('input[name*="[hours]"], input[name*="[amount]"]').removeClass('invisible')

init = ->
  console.log 'init'
  $('form').on 'cocoon:after-insert cocoon:after-remove', ->
    $(@).trigger('rescan.areYouSure')
  get_kind_type()
  set_mailing_address_type()
  $('#mailing_addresses .user_mailing_addresses_as_email input[type=checkbox]').change ->
    set_mailing_address_type()
  $(".project_additional_costs_kind select").change ->
    get_kind_type()
  $(".project_pm_costs_user select, .project_intern_costs_user select").change ->
    amount = $(@).closest('.nested-fields').find('.project_pm_costs_amount, .project_intern_costs_amount').find('input[type=text]')
    $.getJSON "/users/#{$(@).val()}.json", (data) ->
      amount.data 'price-per-hour', data.price_per_hour
  $(".project_pm_costs_duration input, .project_intern_costs_duration input").change ->
    amount = $(@).closest('.nested-fields').find('.project_pm_costs_amount, .project_intern_costs_amount').find('input[type=text]')
    ANUpdate(amount, amount.data('price-per-hour') * $(@).val())
  $("#additional_costs .project_additional_costs_hours input").change ->
    amount = $(@).closest('.nested-fields').find('.project_additional_costs_amount input[type=text]')
    price_per_hour = $(@).closest('.nested-fields').find('.project_additional_costs_price_per_hour input')
    ANUpdate(amount, an(price_per_hour).getNumber() * $(@).val())
  $("#additional_costs .project_additional_costs_price_per_hour input").change ->
    amount = $(@).closest('.nested-fields').find('.project_additional_costs_amount input[type=text]')
    hours = $(@).closest('.nested-fields').find('.project_additional_costs_hours input')
    ANUpdate(amount, hours.val() * an(@).getNumber())
  $("#project_word_count").change ->
    count = $(@).val()
    $('#project_norm_pages').val (count / 220).toFixed(1)
      .addClass 'changed'
    $('#project_character_count').val count * 7
      .addClass 'changed'
    $('#project_norm_lines').val Math.round $('#project_character_count').val() / 55
      .addClass 'changed'
  $('#project_character_count').change ->
    $('#project_norm_lines').val Math.round($(@).val() / 55)
      .addClass 'changed'
  $('#project_percentage_on_total_order_value_net').change ->
    pre_total_order_value_net = an($('#project_other_order_value_net')).getNumber()
    $('.project_additional_costs_amount input, .project_translations_order_value_net input').each ->
      pre_total_order_value_net += an(@).getNumber()
    console.log $('#project_reason_on_total_order_value_net').val()
    pre_total_order_value_net *= -1 if $('#project_reason_on_total_order_value_net').val().includes('discount')
    ANUpdate($('#project_amount_on_total_order_value_net'), pre_total_order_value_net * an(@).getNumber() / 100)
  set_on_total_order_value_net()
  $('.project_reason_on_total_order_value_net select').change ->
    set_on_total_order_value_net()
  $('#translator_order_new_words').change ->
    $('#translator_order_new_norm_pages')
      .val (@.value / 220).toFixed(1)
      .trigger 'change'
    calc_times()
  calc_times()
  $('#translator_order_new_norm_pages').change -> calc_times()
  $('td.time').click ->
    $('#translator_order_hours')
      .val $(@).text()
      .trigger 'change'
    alert "Zeitwert dient nur zur Orientierung"
    $('#translator_order_hours').effect("highlight", {}, 1000)
  $('.translator_order_for input').change -> set_for()
  $('.lang-toggable label').each ->
    if $(@).find('span.en').length == 0
      de = $(@).text()
      en = $(@).attr('for')[17..-1]
      en = en.replace("_id", '')
      en = en.replace(/[_\s]+/g, ' ')
      $(@).html $('<span class="de">').text(de)
      $(@).append $('<span class="en">').text(en)
  set_for()
  set_trackable()
  $('#activity_trackable_type').change ->
    set_trackable()
  $("#bulkEdit .disabled input, #bulkEdit .disabled select").addClass 'hide'
  $("#bulkEdit .disabled a.enable").click (e) ->
    $(@).closest('.disabled').find('input, select')
      .prop 'disabled', (i, v) -> !v
      .toggleClass('hide')
    e.preventDefault()
  $("#bulkEdit").on 'change', "input.toggle", (e) ->
    val = !$(@).prop('checked')
    $(@).closest('div.disabled').find('input:not(.toggle), select, textarea')
      .prop 'disabled', val
      .toggleClass 'hide', val
    e.preventDefault()
  # to only submit changed data
  $('select, textarea').change ->
    $(@).addClass 'changed'
    $(@).closest('.nested-fields').next('input[type=hidden][name$="[id]"]').addClass 'changed'
  $('input[data-autonumeric]').on 'keyup', -> $(@).trigger 'change'
  $('input').change ->
    $(@).addClass 'changed'
    $(@).closest('.nested-fields').next('input[type=hidden][name$="[id]"]').addClass 'changed'
    $(@).closest('.textsize').find('input[type=hidden][name$="[id]"]').addClass 'changed'
  $('.nested-fields .remove_fields').click ->
    $(@).prev().addClass 'changed'
    $(@).closest('.nested-fields').next('input[type=hidden][name$="[id]"]').addClass 'changed'
  $('input').on 'change.datetimepicker', -> $(@).addClass 'changed'
  $('trix-editor').on 'trix-change', -> $("##{$(@).attr 'input'}").trigger 'change'
  $('div[data-changed]').each ->
    # console.log $(@).data 'changed'
    $($(@).data 'changed').addClass 'changed'
    # $($(@).data 'changed').closest('input[data-autonumeric]').trigger 'change'
  $('input[type=checkbox]').change ->
    $(@).prev('input[type=hidden]').addClass 'changed'
  if(0 < $('#presences-outer').length)
    $('#presences-outer')
      .scrollLeft($('#presences-outer').scrollLeft() + $('.today').position().left - $('#presences-outer').width() / 2)
    $('#presences-outer, #actividay')
      .tipsy({ gravity: 's', title: tipsyTitle, html: true, live: true, subgroup: 'tr .urlaub, tr .krank, tr .sonder_urlaub, tr .beantragter_urlaub, tr .anwesend, tr .kind_krank,
       tr .ho, tr .ueberstunden_abbau, tr .ueberstunden_antrag, tr .ausser_haus, tr .geplanter_urlaub, tr .workblock' })
    user_presences_init()
    $('#user-vis ul').on 'click', 'a', (evnt) ->
      $target = $( evnt.currentTarget )
      if evnt.ctrlKey
        if $target.hasClass('single')
          $target.removeClass('single')
          $("#user-vis ul li a").addClass('dttb-col-vis')
          $(".presences-outer table tr").removeClass('d-none')
          user_presences_init()
        else
          $("#user-vis ul li a").removeClass('single').removeClass('dttb-col-vis')
          $(".presences-outer table tbody tr:not(.self)").addClass('d-none')
          $target.addClass('single').addClass('dttb-col-vis')
          $(".presences-outer table tr.#{$target.attr( 'data-value' )}").removeClass('d-none')
      else
        data = JSON.parse(localStorage.getItem("PresenceUserVisibilities")) || {}
        $(".presences-outer table tr.#{$target.attr( 'data-value' )}").toggleClass('d-none', $target.hasClass('dttb-col-vis'))
        data[$target.attr( 'data-value' )] = !$target.hasClass('dttb-col-vis')
        $target.toggleClass('dttb-col-vis')
        localStorage.setItem("PresenceUserVisibilities", JSON.stringify(data))
      $(evnt.target).blur()
      evnt.preventDefault()
      return false
    $('#presences-outer').on 'click', 'th[data-date]', ->
      elem = @
      $.ajax('/act/' + elem.dataset['date']).done (response) ->
        $('#actividay').html(response)
        user_presences_init()
        $("th.active").removeClass('active')
        $(elem).addClass('active')
    $('#actividay').on 'click', 'td:not(:first-child)', (evnt) ->
      presence_cancel()
      $('#new_activity').removeClass('d-none')
      minutes = (($(@).index() - 1) * 15 + 360)
      $('#activity_at').datetimepicker('date', Math.floor(minutes / 60) + ':' + minutes % 60)
      $('#activity_day').datetimepicker('date', $('.working-hours th.date').data('date'))
      if pres_admin == "true"
        user = evnt.currentTarget.parentElement.className.match(/user-(\d+)/)[1]
        $('#activity_user_id').val(user).change().trigger('chosen:updated')


      console.log( Math.floor(minutes / 60), minutes % 60)
    
user_presences_init = ->
  data = JSON.parse(localStorage.getItem("PresenceUserVisibilities")) || {}
  if data
    for k, v of data
      $("#user-vis ul li a[data-value=#{k}]").toggleClass('dttb-col-vis', v)
      $(".presences-outer table tr.#{k}").toggleClass('d-none', !v)

tipsyTitle = (elem) ->
  a = 0
  if @.dataset['title']
    return @.dataset['title']
  else if @.classList.contains('urlaub')
    return 'Urlaub'
  else if @.classList.contains('krank')
    return 'krank'
  else if @.classList.contains('sonder_urlaub')
    return 'Sonder Urlaub'
  else if @.classList.contains('beantragter_urlaub')
    return 'beantragter Urlaub'
  else if @.classList.contains('anwesend')
    return 'anwesend'
  else if @.classList.contains('kind_krank')
    return 'Kind krank'
  else if @.classList.contains('ho')
    return 'Home Office'
  else if @.classList.contains('ueberstunden_abbau')
    return 'Überstunden Abbau'
  else if @.classList.contains('ueberstunden_antrag')
    return 'Überstunden Abbau - Antrag'
  else if @.classList.contains('ausser_haus')
    return 'Ausser Haus'
  else if @.classList.contains('geplanter_urlaub')
    return 'geplanter Urlaub'
  else
    return false

$(document)
  .on 'draw.dt', 'table[id^=dttb-]', ->
    $('tr[id]').click (e) ->
      id = $(@).attr 'id'
      if e.target.nodeName == 'A'
        t_el = $(@)
        tmp = t_el.hasClass('selected')
        setTimeout ->
          table = $('table[id^=dttb-]').DataTable()
          if tmp
            table.row('#' + id).select()
          else
            table.row('#' + id).deselect()
        , 50
        return
      if e.target.classList.contains('select-checkbox')
        return
      path = $(@).closest('table').data('source').replace('.json', '')
      location.assign "#{path}/#{id}"
  # .on 'focus', 'input, select', ->
  #   $(@).prop('readonly', false).focus
  # .on 'blur', 'input, select', ->
  #   $(@).prop('readonly', true)

  .on 'wheel mousewheel', 'input', ->
    hasFocus = $(@).is ':focus'
    initValue = @.value
    elem = @
    @.blur()
    setTimeout ->
      if hasFocus
        elem.focus()
      elem.value = initValue
      return
    , 50
    return

  .on 'click', 'tr[data-uid]', (e) ->
    return if e.target.nodeName == 'A'
    id = $(@).data 'uid'
    path = $(@).closest('table').data 'bpath'
    location.assign path + "/" + id

  .on 'click', '.jump-today', (e) ->
    $('#presences-outer').scrollLeft($('#presences-outer').scrollLeft() + $('.today').position().left - $('#presences-outer').width() / 2)
  .on 'click', '#translation_calcs .textsize-block span', (e) ->
    trgt = $(e.target)
    if trgt.hasClass('source')
      combine = $('.textsize input.form-control', trgt.closest('.textsize-block'))
        .map ->
          @.value
        .get().join ''
      if !trgt.closest('.textsize-block').hasClass('selected') && '' == combine && !confirm('Die Werte sind leer, Sind Sie sicher, dass diese zum Kopieren ausgewählt werden sollen?')
        return
      $('#translation_calcs').toggleClass 'selected'
      trgt.closest('.textsize-block').toggleClass 'selected'
    if trgt.hasClass('target')
      trgtinputs = $('.textsize input.form-control', trgt.closest('.textsize-block'))
      srcinputs = $('#translation_calcs .textsize-block.selected .textsize input.form-control')
      if trgtinputs.length == srcinputs.length
        combine = trgtinputs
          .map ->
            @.value
          .get().join ''
        if '' != combine && !confirm('Die Werte sind NICHT leer, Sind Sie sicher, dass diese überschrieben werden sollen?')
          return
        for i in [0..trgtinputs.length - 1]
          ANUpdate(trgtinputs[i], an(srcinputs[i]).getNumericString())

  # .on 'cocoon:after-insert', 'div', ->
  .on 'cocoon:after-insert', ->
    # $('.chosen.select').chosen('destroy')
    # maybe remove is not needed, but sometimes it seems to recreate, before it managed to destroy
    # $('.chosen-container').remove()
    # $('.chosen.select').chosen
    $('select.select').not('.chosinited').chosen {
      allow_single_deselect: true
      no_results_text: 'No results matched'
      width: '100%'
    }
    $('select.select').addClass('chosinited')
    init()

  .on 'change.datetimepicker error.datetimepicker', '.yadcf-filter-range-date', (evnt) ->
    $(@).keyup()
  .on 'change.datetimepicker error.datetimepicker', '.da-ti-pick', (evnt) ->
    $('span.dateView', @.parentNode).remove()
    if(evnt.date)
      $(@).after('<span class="dateView">' + moment(evnt.date).locale(navigator.language).format('L LT') + '</span>')
    return

  .on 'change.datetimepicker error.datetimepicker', '.da-pick', (evnt) ->
    $('span.dateView', @.parentNode).remove()
    pres = $('#presences')
    if 0 < pres.length
      $('table#presences tr.user-' + $('#presence_user_id').val() + ' td.selected').removeClass('selected')
    if("error" == evnt.type)
      return
    if(evnt.date)
      $(@).after('<span class="dateView">' + moment(evnt.date).locale(navigator.language).format('L') + '</span>')
    if 0 < pres.length
      update_selection(pres)
      #$('input#presence_from').datetimepicker('date', $('table#presences th:not(.month)').eq($(this).index()).data('date'))
    return
  .on 'change', '#presence_user_id', ->
    pres = $('#presences')
    if 0 < pres.length
      update_selection()
  .on 'blur', 'input#translator_order_total', ->
    if 1000 <= an(@).getNumber()
      alert 'Bitte Bestellung durch AR prüfen lassen. Gesamtbetrag: ' + @.value

  # .on 'change.datetimepicker error.datetimepicker', '.ti-pick', (evnt) ->
  #   $('span.dateView', @.parentNode).remove()
  #   if(evnt.date)
  #     $(@).after('<span class="dateView">'+moment(evnt.date).locale(navigator.language).format('LT')+'</span>')
  #   return

  .on 'show.datetimepicker', '.da-ti-pick, .da-pick, .ti-pick, .yadcf-filter-range-date', (evnt) ->
    $('.pickerIsActive').each ->
      if @ != evnt.target
        $(@).datetimepicker('hide')
        $(@).removeClass('pickerIsActive')
    $(evnt.target).addClass('pickerIsActive')
    return

  .on 'hide.datetimepicker', '.da-ti-pick, .da-pick, .ti-pick, .yadcf-filter-range-date', (evnt) ->
    $(evnt.target).removeClass('pickerIsActive')
    return

  .on 'click mouseup touchend focusin', 'div.vac_jumps i', (evnt) ->
    date = $(this).data('vac')
    idx = $('table#presences th:not(.month)[data-date=' + date + ']').index('table#presences th:not(.month)')
    get_range_selection(elem = $('td', $(this).closest('tr'))[idx])
    $('#new_presence').removeClass('d-none')
    $('#presences-outer').scrollLeft($('#presences-outer').scrollLeft() + $(elem).position().left - $('#presences-outer').width() / 2)

  .on 'click mouseup touchend focusin', (evnt) ->
    trgt = $(evnt.target)
    if(0 < $('.bootstrap-datetimepicker-widget').length)
      widgin = trgt.closest('.bootstrap-datetimepicker-widget')
      if(0 == widgin.length)
        $('.bootstrap-datetimepicker-widget').each ->
          $(@).parent().find('input.datetime, input.date, input.time, input.yadcf-filter-range-date').each ->
            if @ != trgt[0]
              $(@).datetimepicker('hide')
              $(@).removeClass('pickerIsActive')
            # else
            #   $(@).addClass('pickerIsActive')
            # return
      # else if trgt.is('input') && !trgt.hasClass('pickerIsActive')
      #   if trgt.hasClass('datetime') || trgt.hasClass('date') || trgt.hasClass('time') || trgt.hasClass('yadcf-filter-range-date')
      #     dest = $(trgt).parent().find('input.pickerIsActive')
      #     if dest[0] != trgt[0]
      #       dest.datetimepicker('hide')
      #       dest.removeClass('pickerIsActive')

    if trgt.is('label.date')
      evnt.preventDefault()
      return false
    if trgt.hasClass('datetime') || trgt.hasClass('date') || trgt.hasClass('time') || trgt.hasClass('yadcf-filter-range-date')
      if trgt.hasClass('pickerIsActive')
        return
      if !trgt.is('input')
        trgt = trgt.parent().find('input.datetime, input.date, input.time')
        if 0 == trgt.length
          return
      trgt.datetimepicker('show')
      trgt.addClass('pickerIsActive')
    # console.log($(evnt.target))
    # console.log(evnt.type)
    return
  .on 'click', 'table#presences td.editable', (evnt) ->
    $('#new_presence').removeClass('d-none')
    activity_cancel()

    # if(evnt.target.classList.contains('add'))
    # if(evnt.target.classList.contains('edit'))
    # if(evnt.target.classList.contains('end'))
    # debugger;

    # $('#select-box option').each(function(){
    #   if (this.value == 'bar') {
    #     exists = true;
    #     return false;
    #   }
    # });
    if pres_admin == "true"
      user = evnt.currentTarget.parentElement.className.match(/user-(\d+)/)[1]
    etype = evnt.currentTarget.firstElementChild.className
    if evnt.ctrlKey && !["", "anwesend"].includes(etype)
      get_range_selection(this)
      return
      firstdate = lastdate = this
      weekend = false
      weekends = 0
      while firstdate.previousElementSibling && (etype == firstdate.previousElementSibling.firstElementChild.className || ((lastdate.previousElementSibling.classList.contains('freeday') || lastdate.previousElementSibling.classList.contains('personal_freeday')) && '' == firstdate.previousElementSibling.firstElementChild.className))
        if firstdate.previousElementSibling.classList.contains('freeday') || firstdate.previousElementSibling.classList.contains('personal_freeday')
          weekends++
          if etype == firstdate.previousElementSibling.firstElementChild.className
            weekend = true
        firstdate = firstdate.previousElementSibling
      while firstdate && firstdate != this && (firstdate.classList.contains('freeday') || firstdate.classList.contains('personal_freeday')) && etype != firstdate.firstElementChild.className
        weekends--
        firstdate = firstdate.nextElementSibling
      while lastdate.nextElementSibling && (etype == lastdate.nextElementSibling.firstElementChild.className || ((lastdate.nextElementSibling.classList.contains('freeday') || lastdate.nextElementSibling.classList.contains('personal_freeday')) && '' == lastdate.nextElementSibling.firstElementChild.className))
        if lastdate.nextElementSibling.classList.contains('freeday') || lastdate.nextElementSibling.classList.contains('personal_freeday')
          weekends++
          if etype == lastdate.nextElementSibling.firstElementChild.className
            weekend = true
        lastdate = lastdate.nextElementSibling
      while lastdate && lastdate != this && (lastdate.classList.contains('freeday') || lastdate.classList.contains('personal_freeday')) && etype != lastdate.firstElementChild.className
        weekends--
        lastdate = lastdate.previousElementSibling
      if 0 == weekends
        weekend = false
      dfdate = $('table#presences th:not(.month)').eq($(firstdate).index()).data('date')
      if 'true' != pres_admin && dfdate < pres_today
        if lastdate != firstdate
          dldate = $('table#presences th:not(.month)').eq($(lastdate).index()).data('date')
          if(dldate < pres_today)
            $('input#presence_from').datetimepicker('date', null)
            $('input#presence_to').datetimepicker('date', null)
          else if pres_today < dldate
            $('input#presence_from').datetimepicker('date', pres_today)
            $('input#presence_to').datetimepicker('date', dldate)
            $('input#presence_weekend').prop('checked', weekend)
            $('#presence_status').val(etype).change()
          else
            $('input#presence_from').datetimepicker('date', pres_today)
            $('input#presence_to').datetimepicker('date', null)
            $('input#presence_weekend').prop('checked', weekend)
            $('#presence_status').val(etype).change()
        else
          $('input#presence_from').datetimepicker('date', null)
          $('input#presence_to').datetimepicker('date', null)
          if(0 < etype.length)
            $('#presence_status').val(etype).change()

      else
        $('input#presence_from').datetimepicker('date', $('table#presences th:not(.month)').eq($(firstdate).index()).data('date'))
        if lastdate != firstdate
          $('input#presence_to').datetimepicker('date', $('table#presences th:not(.month)').eq($(lastdate).index()).data('date'))
        else
          $('input#presence_to').datetimepicker('date', null)
        $('input#presence_weekend').prop('checked', weekend)
        $('#presence_status').val(etype).change()
      if pres_admin == "true"
        $('#presence_user_id').val(user).change().trigger('chosen:updated')
        $('#ueberstunden_antrag_option').remove()
        if etype == 'ueberstunden_antrag'
          $('.btn-ot').removeClass('d-none')
          opt = new Option("Überstunden Abbau - Antrag", etype, false. true)
          opt.id = etype + '_option'
          document.getElementById('presence_status').appendChild(opt)
    else
      if pres_admin == "true"
        user = evnt.currentTarget.parentElement.className.match(/user-(\d+)/)[1]
        $('#ueberstunden_antrag_option').remove()
        if etype == 'ueberstunden_antrag'
          $('.btn-ot').removeClass('d-none')
          opt = new Option("Überstunden Abbau - Antrag", etype, false. true)
          opt.id = etype + '_option'
          document.getElementById('presence_status').appendChild(opt)
        
      last = $('input#presence_to').datetimepicker('date')
      first = $('input#presence_from').datetimepicker('date')
      if(0 < etype.length)
        $('#presence_status').val(etype).change()

      if evnt.shiftKey && first
        first = first.format("YYYY-MM-DD")
        date = $('table#presences th:not(.month)').eq($(this).index()).data('date')
        if last
          last = last.format("YYYY-MM-DD")
        else
          last = first
        if date < first
          first = date
        else
          last = date
        if 'true' != pres_admin && first < pres_today
          if last != first
            if(last < pres_today)
              last = first = ""
            else
              first = pres_today
          else
            last = first = ""
        if "" == first
          $('input#presence_from').datetimepicker('date', null)
        else
          $('input#presence_from').datetimepicker('date', first)
        if first < last
          $('input#presence_to').datetimepicker('date', last)
        else
          $('input#presence_to').datetimepicker('date', null)
        if "" != first
          $('input#presence_weekend').prop('checked', this.classList.contains('freeday') || this.classList.contains('personal_freeday'))
        else
          $('input#presence_weekend').prop('checked', false)
        if pres_admin == "true"
          $('#presence_user_id').val(user).change().trigger('chosen:updated')
          $('.btn-vac').addClass('d-none')
          $('.btn-ot').addClass('d-none')

      else
        first = $('table#presences th:not(.month)').eq($(this).index()).data('date')
        if 'true' != pres_admin && first < pres_today
          return
        $('input#presence_from').datetimepicker('date', first)
        $('input#presence_to').datetimepicker('date', null)
        $('input#presence_weekend').prop('checked', this.classList.contains('freeday') || this.classList.contains('personal_freeday'))
        if pres_admin == "true"
          $('#presence_user_id').val(user).change().trigger('chosen:updated')
          $('.btn-vac').addClass('d-none')
          $('.btn-ot').addClass('d-none')


    # console.log($(this).index())
  .on 'click', '#presence-cancel', ->
    presence_cancel()
  .on 'click', '#activity-cancel', ->
    activity_cancel()


  .on 'click', '.js-copy', ->
    text = $(this).attr('data-copy')
    if(undefined == text)
      text = this.parentElement.textContent
    el = $(this)
    copyToClipboard(text, el)

  # .one 'focus.autoExpand', 'textarea.autoExpand', ->
  #   savedValue = @.value
  #   @.value = ''
  #   @.baseScrollHeight = @.scrollHeight
  #   @.value = savedValue
  #   minRows = @.getAttribute('data-min-rows')|2
  #   @.rows = minRows;
  #   rows = Math.ceil((@.scrollHeight - @.baseScrollHeight) / parseFloat($(@).css('line-height')))
  #   @.rows = minRows + rows

  .on 'input.autoExpand', 'textarea.autoExpand', ->
    minRows = @.getAttribute('data-min-rows') | 2
    @.rows = minRows
    rows = Math.ceil((@.scrollHeight - @.baseScrollHeight) / parseFloat($(@).css('line-height')))
    @.rows = minRows + rows

presence_cancel = ->
  $('#new_presence').addClass('d-none')
  $('input#presence_to').datetimepicker('date', null)
  $('input#presence_from').datetimepicker('date', null)
  $('input#presence_weekend').prop('checked', false)
  $('#ueberstunden_antrag_option').remove()
  $('#presence_status').val('').change()

activity_cancel = ->
  $('#new_activity').addClass('d-none')

$(window).on 'turbolinks:load', ->
  # $('.bootstrap-table').on('reorder-column.bs.table', function (evnt){
  #   debugger;
  #   console.log(evnt);
  #   // $(this).bootstrapTable('destroy').bootstrapTable({});
  #   $(this).dragtable('destroy').dragtable({});
  #   $.fn.bootstrapTable.Constructor.prototype.resetView.apply(this);
  # });

  $('table#trans_order_tab').DataTable({ searching: false, paging: false, lengthChange: false, info: false, retrieve: true })
  $('#pm_costs').on 'cocoon:after-insert', ->
    nested_fields = $(@).find('.nested-fields')
    if nested_fields.length == 1
      first_project_manager = nested_fields.first().find('select')
      first_project_manager
        .val $('#project_first_project_manager_id').val()
        .trigger 'chosen:updated'
      setTimeout ->
        first_project_manager.trigger('change')
      , 100

  $('a#test').click (e) ->
    oldOrder = [0, 2, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27]
    console.log oldOrder
    newOrder = [0..$('#dttb-projects').find('th').length]
    console.log newOrder
    newOrder.forEach (item, index, array) ->
      console.log "foo"
      console.log index
      array[index] = oldOrder[index] || item
    console.log newOrder
    $('#dttb-projects').DataTable().colReorder.order(newOrder)
    e.preventDefault()
  $('.bulkEditModal form label').append('<input type="checkbox" role="toggle" class="toggle ml-auto"/>')
  $('.toggle').bootstrapToggle()
  $('.bulkEditModal .enable').hide()

  # enable chosen js
  # $('.chosen.select').chosen('destroy')
  $('select.select').chosen('destroy')
  # maybe remove is not needed, but sometimes it seems to recreate, before it managed to destroy
  $('.chosen-container:not([id^=yadcf_filter])').remove()
  # $('.chosen.select').chosen
  $('select.select').chosen {
    allow_single_deselect: true
    no_results_text: 'No results matched'
    width: '100%'
  }
  $('select.select').addClass('chosinited')
  $('.chosen').on 'change', -> $("[name='#{@.name}']").addClass 'changed'
  # $('input, select').prop('readonly', true);
  $('.historyModal > .modal').appendTo('body')

  $.each $('textarea.autoExpand'), ->
    savedValue = @.value
    @.value = ''
    @.baseScrollHeight = @.scrollHeight
    @.value = savedValue
    minRows = @.getAttribute('data-min-rows') | 2
    @.rows = minRows
    rows = Math.ceil((@.scrollHeight - @.baseScrollHeight) / parseFloat($(@).css('line-height')))
    @.rows = minRows + rows

  # $.each $('.yadcf-filter-range-date'), ->
  #   $(this).attr('data-toggle', 'datetimepicker').attr('data-target', '#'+this.id)
  $.each $('input[type=datetime-local], .da-ti-pick'), ->
    $(this).attr('data-toggle', 'datetimepicker').attr('data-target', '#' + this.id).attr('readonly', true).attr('spellcheck', false)
    $(this).datetimepicker {
      debug: false
      locale: moment.locale('de')
      allowInputToggle: true
      format: 'YYYY-MM-DDTHH:mm'
      readonly: true
      ignoreReadonly: true
      buttons: { showToday: true, showClear: true, showClose: true }
      icons: { time: 'far fa-clock', date: 'far fa-calendar-alt', clear: 'far fa-calendar-times', today: 'fas fa-calendar-day', close: 'fa fa-times' }
      keepInvalid: true
      useCurrent: false
    }
    return
  # $('input[type=datetime-local]').datetimepicker
  #   locale: 'de'
  #   allowInputToggle: true
  init_date_pick()
  # $('input[type=datetime-local]').datetimepicker
  #   locale: 'de'
  #   allowInputToggle: true
  $.each $('.ti-pick'), ->
    $(this).attr('data-toggle', 'datetimepicker').attr('data-target', '#' + this.id).attr('spellcheck', false)
    $(this).datetimepicker {
      debug: false
      locale: moment.locale('de')
      allowInputToggle: true
      format: 'HH:mm'
      ignoreReadonly: true
      buttons: { showToday: true, showClear: true, showClose: true }
      icons: { time: 'far fa-clock', date: 'far fa-calendar-alt', clear: 'far fa-calendar-times', today: 'fas fa-calendar-day', close: 'fa fa-times' }
      keepInvalid: true
      useCurrent: false
    }
    return
  # $('input[type=datetime-local]').datetimepicker
  #   locale: 'de'
  #   allowInputToggle: true
  $('.dataTables_filter input[type="search"]').focus()
  $('.simple_form input[type!=hidden]:not(.noAutoFocus):first').focus()
  init()
  $('th').tooltip()
  $('form').areYouSure({ 'addRemoveFieldsMarksDirty': true }).on('dirty.areYouSure', ->
    $('input[name=commit]').attr('disabled', false)
  ).on('clean.areYouSure', ->
    $('input[name=commit]').attr('disabled', true)
  ).find('input[name=commit]').attr('disabled', true)
  $('form.ays-disabled input[name=commit]').removeAttr('disabled')

  $('.js-tooltip, label[data-toggle=tooltip]').tooltip()

  $('input[type="submit"]').click ->
    $(@).closest('form').append $("<input type='hidden' name='#{@.name}' value='#{@.value}' class='changed ays-ignore'>")

  $('form[id^="edit_"]').on 'submit', ->
    $('input:not(.changed), textarea:not(.changed), select:not(.changed)').prop('disabled', true)
    # $('input[type=hidden]').prop('disabled', false)
    $('input[name=_method], input[name=authenticity_token]').prop('disabled', false)
    input_count = $(@).serializeArray().length
    input_count += $(this).find('input[type=file].changed').length
    if $(@).find('input[type=hidden][name=commit]').length > 0 and input_count < 4 # prevent submittion without changes but allow commits like create_email etc.
      $(@).find('input[name=commit]').attr 'disabled', true
      alert "No changes to submit."
      $('input:not(.changed), textarea:not(.changed), select:not(.changed)').prop('disabled', false)
      $('input[name=_method], input[name=authenticity_token]').prop('disabled', true)
      event.preventDefault()

  setTimeout(ccResizeTable, 100)


$(window).resize ->
  ccResizeTable()
  return


$(document)
  .on 'show.bs.tab', '.nav-link', (e) ->
    if 0 == @.hash.indexOf('#edit') && undefined != $(@).data('formurl')
      context = document.getElementById(@.hash.substr(1))
      $('.spinner-border', context).show()
      $('form', context).hide()
      $.ajax $(this).data('formurl'), { context: context }
        .done (response) ->
          $('form', @).replaceWith response.data
          $('form', @).show()
          $('.spinner-border', @).hide()
          $(window).trigger('turbolinks:load')
  .on 'keydown keyup', (evnt) ->
    @pressed = @pressed || false
    if [16, 17].includes(evnt.keyCode)
      if 'keydown' == evnt.type
        if !@pressed
          @pressed = true
          @pane = $('#presences')
          if(@pane)
            @pane.addClass('expand')
      else if(@pane)
        @pane.removeClass('expand')
        @pressed = false
  .on 'click', '.multi-download-button', () ->
    a = $('a', this.parentElement.parentElement).map((item) -> this.href).get()
    multiDownload(a)
  .on 'dnd-upload:initialize', (evnt) ->
    container = evnt.detail.iconContainer
    $(container).trigger 'dirty.areYouSure'
    $(container).closest('form').find('input#file_exchange_files').change()
  .on 'dnd-upload:cancel', (evnt) ->
    container = evnt.detail.iconContainer
    setTimeout ->
      if null == container.firstElementChild
        $(container).trigger 'clean.areYouSure'
      $(container).closest('form').find('input#file_exchange_files').change()
    , 100

# $.fn.dataTable.ext.errMode = ( settings, helpPage, message ) ->
#   if 7 == helpPage
#     alert 'Es scheint eine Überlastung auf dem Server aufgetreten zu sein, bitte in kürze die Seite neu laden. Sollte das Problem länger als 5 minuten anhalten, bitte melden! ('+settings.jqXHR.status+')'
#   else
#     alert message
