// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

window.$ = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

import "chosen-js/chosen"
import 'css/application'

require('jquery-ui/themes/base/core.css');
require('jquery-ui/themes/base/tooltip.css');
require('jquery-ui/themes/base/theme.css');
// require("jquery-ui/ui/widgets/tooltip")
import "jquery-ui/ui/widgets/tooltip"

require("@nathanvda/cocoon")
import 'chosen-js'

// import 'popper.js'
// import bootstrap from 'bootstrap'
import 'bootstrap'

import 'bootstrap4-toggle'

import 'tempusdominus-bootstrap-4'

import 'lib/datatables'


// require('lib/ays-beforeunload-shim')
import 'lib/ays-beforeunload-shim'
// require('lib/jquery.are-you-sure')
import 'lib/jquery.are-you-sure'
import "lib/jquery.tipsy"
import multiDownload from 'lib/multi-download'
window.multiDownload = multiDownload;

import "lib/custom"


import 'jquery-lazyload'


import AutoNumeric from 'autonumeric'
// global.AutoNumeric = AutoNumeric
global.an = function(jQElement) {
  return AutoNumeric.getAutoNumericElement(jQElement[0] || jQElement)
}
global.ANUpdate = function(jQElement, value) {
  AutoNumeric.getAutoNumericElement(jQElement[0] || jQElement).set(value)
  $(jQElement).trigger('change')
}


function init() {
  if ($('#user_rating').length) {
    new AutoNumeric('#user_rating', {decimalCharacter: ',', digitGroupSeparator: '.', decimalPlaces: 1, minimumValue: 0, maximumValue: 5, modifyValueOnWheel : false, unformatOnSubmit : true})
  }
  AutoNumeric.multiple('[data-autonumeric=amount]', [AutoNumeric.getPredefinedOptions().French, { modifyValueOnWheel : false, unformatOnSubmit : true }]);
  // AutoNumeric.multiple('[data-autonumeric=percentage]', [AutoNumeric.getPredefinedOptions().percentageEU2dec, { modifyValueOnWheel : false, unformatOnSubmit : true }]);
  AutoNumeric.multiple('[data-autonumeric=percentage]', [AutoNumeric.getPredefinedOptions().commaDecimalCharDotSeparator, { modifyValueOnWheel : false, unformatOnSubmit : true, suffixText: ' %' }]);
  // new AutoNumeric('[data-autonumeric]').french();

  $("img.lazy").lazyload();
}

document.addEventListener('turbolinks:load', init)
// document.addEventListener('turbolinks:load', () => {
//   document.addEventListener('cocoon:after-insert', initAutoNumeric)
// })
$(document).on('cocoon:after-insert', (e, inserted) => {
  AutoNumeric.multiple($('[data-autonumeric=amount]', inserted).toArray(), [AutoNumeric.getPredefinedOptions().French, { modifyValueOnWheel : false, unformatOnSubmit : true }]);
  AutoNumeric.multiple($('[data-autonumeric=percentage]', inserted).toArray(), [AutoNumeric.getPredefinedOptions().commaDecimalCharDotSeparator, { modifyValueOnWheel : false, unformatOnSubmit : true, suffixText: ' %' }]);
  // inserted.querySelectorAll('[data-autonumeric=amount]').forEach((item, i) => {
  //   new AutoNumeric(item, [AutoNumeric.getPredefinedOptions().French, { modifyValueOnWheel : false, unformatOnSubmit : true }])
  // });
})


import ActiveStorageDragAndDrop from 'active_storage_drag_and_drop'

ActiveStorageDragAndDrop.start()


import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

// # Change the config to fix the flicker
config.mutateApproach = 'sync'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(
  fas, far
)

dom.watch()
